import React from 'react'
import { tableIcons } from './tableIcons'
import MaterialTable from 'material-table'

const Genre = (props) => <span className='genre-pill'>{props.name}</span>

const AlbumsIndex = (props) => {
  const editAlbumPath = (id) => props.editPath.replace(':id', id)

  const columns = [
    { title: 'Artist', field: 'artist', sort: 'asc' },
    { title: 'Title', field: 'title' },
    { title: 'Released', field: 'released' },
    {
      title: 'Genres',
      field: 'genres',
      render: row => (<div>{row.genres.map(g => <Genre name={g.name} key={g.id} />)}</div>)
    },
    { title: 'Label', field: 'label' },
    { title: 'Format', field: 'format' },
    { title: 'Date Added', field: 'created_at', render: row => (<div>{row.created_at.match(/[^T]*/)[0]}</div>) },
    { title: 'Notes', field: 'notes' },
    {
      title: '',
      render: row => (<div><a href={editAlbumPath(row.id)}>edit</a> delete</div>)
    }
  ]

  return (
    <MaterialTable
      icons={tableIcons}
      options={{
        pageSize: 25,
        pageSizeOptions: [25, 50, 100]
      }}
      style={{ zoom: '0.8' }}
      title='Albums'
      data={props.albums}
      columns={columns}
    />
  )
}

export default AlbumsIndex
