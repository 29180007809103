import React from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import styled, { css } from 'styled-components'

const Album = (data) => {
  const album = {
    genres: []
  }
  for (var [key, value] of data.entries()) {
    if (value) {
      if (key === 'genres') album.genres.push(value)
      else album[key] = value
    }
  }
  return album
}

const NewAlbum = (props) => {
  const toSelectOptions = (option) => ({ value: option, label: option })
  const artists = props.artists.map(toSelectOptions)
  const genres = props.genres.map(toSelectOptions)
  const labels = props.labels.map(toSelectOptions)
  const formats = [
    { value: 'LP', label: 'LP' },
    { value: '45', label: '45' },
    { value: '12" Single', label: '12" Single' }
  ]
  const defaultFormat = formats.find(format => format.value === 'LP')

  const GridForm = styled.form`
    display: grid;
    grid-gap: .5em;
  `

  const inputStyles = css`
    /* background: #ffffff; */
    width: 100%;
    margin: 0;
    border-radius: 4px;
    border-color: hsl(0, 0%, 80%);
    border-width: 1px;
  `

  const TextInput = styled.input`
    ${inputStyles}
  `
  const TextArea = styled.textarea`
    ${inputStyles}
  `

  const SubmitButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
  `

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new window.FormData(event.target)
    const album = Album(data)
    window.Http.post(props.albumsPath, { album }, function (res) {
      console.log(res)
      window.location = res.data.path
    })
  }

  return (
    <GridForm onSubmit={handleSubmit}>
      <CreatableSelect name='artist' isClearable placeholder='Artist' options={artists} autoFocus />
      <TextInput type='text' name='title' placeholder='Title' />
      <TextInput type='number' name='released' placeholder='Released' />
      <CreatableSelect name='label' isClearable placeholder='Label' options={labels} />
      <CreatableSelect name='genres' isClearable placeholder='Genres' options={genres} isMulti />
      <Select name='format' placeholder='Format' options={formats} defaultValue={defaultFormat} />
      <TextArea name='notes' placeholder='Notes' />
      <SubmitButtonWrapper>
        <button type='submit'>Submit</button>
      </SubmitButtonWrapper>
    </GridForm>
  )
}

export default NewAlbum
